.title {
  font-family: GALACTIC VANGUARDIAN NCV;
}

.button {
  border: 2px solid var(--white);
  color: var(--primary-2);
  font-family: ChubbyChoo;
  font-size: 1rem;
  font-size: 28px;
  line-height: 13px;
  padding: 11px 15px;
  padding: 1rem;
  text-transform: uppercase;

  span {
    vertical-align: text-top;
  }

  &:hover {
    background-color: var(--primary-2);
    color: var(--black);
  }
}

.gameframe {
  position: relative;
  margin: auto;
  width: 800px;
  max-width: 100%;
  border: 2px solid var(--primary-2);
  margin-bottom: 2rem;

  .game,
  .logo-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .logo-wrapper {
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .game {
    z-index: 0;
  }
}

.game {
  width: 100%;
  height: 100%;
  border: 1rem solid var(--black);
}

.is-landing-page {
  .game {
  }
}

.logo {
  margin: 1rem auto;
  margin-bottom: 1rem;
  max-width: 50vw;
}

.main {
  padding: 1rem;
}

.featured-image {
  cursor: pointer;
}

@media screen(sm) {
  .logo {
    max-width: 100vw;
  }

  .gameframe {
    max-height: 100%;
  }
}

.spin {
  animation-name: spin;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.playersCount{
  color: #05bed6 !important;
  font-weight: bold;
 /* text-align: center; */
 /* width: 800px; */
 /* margin: auto; */
 margin-bottom: 0.25rem;
 max-width: 100%;
}
.upperGame{
  justify-content: space-between !important;
  display: flex;
  width: 800px;
  margin: auto;
  max-width: 100%;
}
.w-800{
  width: 500px;
max-width: 100%;
margin: auto;
}

.gameDescription{
  justify-content: space-between !important;
  display: flex;
  width: 100%;
  margin: auto;
  max-width: 100%;
  font-weight: bold;
  color: white;
  margin-top: 2.5em;
  padding-left: 1.1em;
}

.gameDescriptionBefore{
  margin-top: -1.1em !important;
  padding-left: 0 !important;
}

.specialPrize{
  color: #ff8e06 !important;
  font-size: .85em;
  font-weight: bold;
  margin-top: 2rem;
}